<script setup>
import { supabase } from '@/utils/supabase.js'
import { useStampStore } from '@/stores/stamp'
import { ref, inject } from 'vue'
import xmas_head from '@/assets/images/img_xmas_head.png'

// injectからdayjsを呼び出す
const dayjs = inject('dayjs')
const store = useStampStore()
const message_data = ref('')
const count_down = ref(10)
const is_xmas = ref(false)
const is_count_year_mode = ref(false)
const is_new_year = ref(false)

const date = dayjs().startOf('day')

if(date.isAfter('2024-12-25')) { // クリスマス後の処理
  // 年明けまでの処理
  is_count_year_mode.value = true
  count_down.value = dayjs('2025-1-1').startOf('day').diff(date, 'days')
  if (count_down.value <= 0) {
    // 年明け当日、または過ぎている
    is_new_year.value = true
  }
} else {
  // 12月25日との差分を日数で取得
  count_down.value = dayjs('2024-12-25').startOf('day').diff(date, 'days')
  if (count_down.value <= 0) {
    // クリスマス当日、または過ぎている
    is_xmas.value = true
  }
}

const getMessage = async () => {
  const { data:data, error } = await supabase
      .from('notice_messages')
      .select('message')
      .order('created_at', { ascending: false })
      .limit(1)
      .single()

  message_data.value = data
}
getMessage()
</script>

<template>
  <div class="w-11/12">
    <img class="w-full" :src="xmas_head">
  </div>

  <div class="fixed top-[15vh] left-0 z-50 w-full">
    <p class="text-center text-5xl" style="color: #186D21; text-shadow:
       3px 3px 1px white, -3px -3px 1px white,
       -3px 3px 1px white, 3px -3px 1px white,
       3px 0px 1px white, -3px -0px 1px white,
       0px 3px 1px white, 0px -3px 1px white;">
      <template v-if="is_xmas">
        <span class="text-[4rem]" style="color: #14268D;">
          Merry<br>Christmas!
        </span>
      </template>
      <template v-else-if="is_new_year">
        <span class="text-[4rem]" style="color: #BC4D26;">
          Happy<br>New Year!
        </span>
      </template>
      <template v-else>
        <span style="color: #14268D;">
          <template v-if="is_count_year_mode">
            <span class="text-[4rem]">ことし</span>も
          </template>
          <template v-else>
            <span class="text-[4rem]">クリスマス</span>まで
          </template>
        </span>

        <br>
        あと<span class="text-[4.2rem]" style="color: #BC4D26;">{{ count_down }}</span>にち
      </template>
    </p>
    <div class="pt-7 pl-10 flex flex-col">
      <div class="relative mb-2">
        <p class="kosugi-maru-regular leading-8 inline-block text-xs bg-[rgba(256,256,256,0.8)] rounded-lg pl-5 pr-4 before:inline-block before:w-[3rem] before:h-[4rem] before:content-[''] before:bg-[url('@/assets/images/item_text-frame.svg')] before:bg-cover before:absolute before:top-[-1.7rem] before:left-[-1.4rem]">
          {{ message_data.message }}
        </p>
      </div>
    </div>
  </div>

  <div class="fixed bottom-10 left-0 z-10">
    <div class="overflow-hidden mb-7">
      <div class="relative top-0 left-[14px]">
        <div class="w-dvw aspect-square transform scale-[1.15]">
          <div class="w-full h-full bg-[url('@/assets/images/xmas_nyampiee.gif')] bg-cover bg-no-repeat"></div>
        </div>
      </div>
    </div>

    <div class="kosugi-maru-regular pl-2 text-[11px] text-[#A06265] leading-4">
      <p class="mb-2">にゃんぴーのオリジナル作品です🐾<br>無断転載・加工・商用利用、AI学習は禁止です。</p>
      <p>This is Nyampiee's original work🐾<br>No unauthorized use, editing, commercial use, or AI training.</p>
    </div>
  </div>
</template>

<style scoped>
</style>
