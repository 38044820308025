import { createRouter, createWebHistory } from 'vue-router'
import { supabase } from '@/utils/supabase.js';
import { useStampStore } from '@/stores/stamp'
import HomeView from '@/views/HomeView.vue'
import RegisterView from '@/views/RegisterView.vue'
import LoginView from '@/views/LoginView.vue'
import XmasView from '@/views/XmasView.vue'
// import ErrorView from '@/views/ErrorView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: XmasView
    },
    {
      path: '/register',
      name: 'Register',
      component: RegisterView
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginView
    },
    {
      path: '/:catchAll(.*)',
      name: 'not-found',
      component: XmasView  // TODO: にゃんぴーが謝ってる404とか面白そう
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  const store = useStampStore()

  // NOTE: ログイン状態確認
  if (store.auth.loggedIn === false) {
    await isAuth()
  }

  // NOTE: 未ログインの場合は、ログインページへリダイレクト
  // NOTE: ログイン済みの場合は、login、registerページへのアクセスを禁止
  if (to.name === 'Login' || to.name === 'Register') {
    if (store.auth.loggedIn) {
      next({ name: 'Home' })
    } else {
      next()
    }
  } else {
    if (!store.auth.loggedIn) {
      next({ name: 'Login' })
    } else {
      next()
    }
  }


  // ------------- functions -------------
  // NOTE: ログイン状態確認
  async function isAuth() {
    return new Promise(async resolve => {
      const { data } = await supabase.auth.getUser()
      if (data.user) {
        store.setLoggedIn(true)
        store.setAuth(data.user)
      } else {
        store.setLoggedIn(false)
        store.setAuth(null)
      }
      resolve()
    })
  }
})

export default router
